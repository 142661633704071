<template>
  <div>
    <a-empty :image="ImageEmpty" :imageStyle="{ height: '200px' }">
      <span slot="description" class="ndl-text-desc-small ndl-padding">
        快速创建视图物化任务，并自动化执行。
        <a> 关于物化 <i class="ndl-icon-link"></i> </a>
      </span>
      <div>
        <a-button type="primary" @click="toConfig()">
          去配置
          <i class="ndl-icon-arrow-right" style="padding-left: 5px"></i>
        </a-button>
      </div>
    </a-empty>
  </div>
</template>

<script>
import ImageEmpty from "@/assets/images/ai_schedule.svg";
import showComponent from "./show-component";

export default {
  data() {
    return {
      ImageEmpty: ImageEmpty
    };
  },
  methods: {
    toConfig() {
      showComponent.setShow({
        MaterializeTitle: true,
        MaterializeForm: true
      });
    }
  }
};
</script>
