<template>
  <Pane>
    <a-spin :spinning="loading" style="height: 100%">
      <a-icon slot="indicator" type="loading" style="font-size: 32px" spin />
      <div style="height: 100%">
        <component
          v-for="item in componentList"
          :is="item.component"
          :key="item.key"
        >
          {{ item.slot ? item.slot : null }}
        </component>
      </div>
    </a-spin>
  </Pane>
</template>

<script>
import Pane from "../../pane-container";
import materialize from "./materialize";
import showComponent from "./show-component";

export default {
  components: { Pane },
  data() {
    return {
      componentList: []
    };
  },
  watch: {
    $route() {
      this.initMaterialize();
    },
    showComponent: {
      deep: true,
      immediate: true,
      handler: function() {
        this.componentList = showComponent.getShowComponets();
      }
    }
  },
  computed: {
    loading() {
      return materialize.loading;
    },
    showComponent() {
      return showComponent;
    }
  },
  created() {
    this.initMaterialize();
  },
  methods: {
    initMaterialize() {
      const datasheetId = this.$route.params.datasheetId;
      if (datasheetId) {
        materialize.init(datasheetId);
      }
    }
  }
};
</script>
