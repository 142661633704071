import { COMPOSITE_UNITS, TIME_UNITS } from "./constant";

/**
 * 调度频率选项类
 */
export class Frequency {
  constructor(units, timeUnit) {
    const timeUnitObj = COMPOSITE_UNITS[timeUnit];
    if (!timeUnitObj) {
      throw new Error("类型不存在");
    }

    this.units = units; // 时间间隔
    this.timeUnit = timeUnitObj.unit; // 时间单位
    this.desc = timeUnitObj.desc; // 描述文本
    this.isManual = false;
    this.isCustom = false;
    // 手动物化
    if (this.timeUnit === "manual") {
      this.isManual = true;
    }
    // 自定义
    if (this.timeUnit === "custom") {
      this.isCustom = true;
    }
  }

  get value() {
    const { units, timeUnit, isManual, isCustom } = this;
    return JSON.stringify({ units, timeUnit, isManual, isCustom });
  }

  get label() {
    let label = this.desc;
    switch (this.timeUnit) {
      case COMPOSITE_UNITS.minutes.unit:
      case COMPOSITE_UNITS.hours.unit:
        label = `每 ${this.units} ${this.desc}`;
        break;
      case COMPOSITE_UNITS.days.unit:
      case COMPOSITE_UNITS.weeks.unit:
      case COMPOSITE_UNITS.months.unit:
        label = `每${this.desc}`;
    }
    return label;
  }
}

/**
 * 获取 自定义调度频率 的下拉选项对象列表
 */
export function getFrequencys() {
  return [
    new Frequency("manual", COMPOSITE_UNITS.manual.unit),
    new Frequency(5, COMPOSITE_UNITS.minutes.unit),
    new Frequency(15, COMPOSITE_UNITS.minutes.unit),
    new Frequency(30, COMPOSITE_UNITS.minutes.unit),
    new Frequency(1, COMPOSITE_UNITS.hours.unit),
    new Frequency(2, COMPOSITE_UNITS.hours.unit),
    new Frequency(3, COMPOSITE_UNITS.hours.unit),
    new Frequency(4, COMPOSITE_UNITS.hours.unit),
    new Frequency(8, COMPOSITE_UNITS.hours.unit),
    new Frequency(12, COMPOSITE_UNITS.hours.unit),
    new Frequency(1, COMPOSITE_UNITS.days.unit),
    new Frequency(1, COMPOSITE_UNITS.weeks.unit),
    new Frequency(1, COMPOSITE_UNITS.months.unit),
    new Frequency("custom", COMPOSITE_UNITS.custom.unit)
  ];
}

/**
 * 获取 调度单位 列表
 */
export function getTimeUnits() {
  const list = [];
  for (const key in TIME_UNITS) {
    if (Object.hasOwnProperty.call(TIME_UNITS, key)) {
      list.push(TIME_UNITS[key]);
    }
  }
  return list;
}

/**
 * 获取当前调度频率
 */
export function getCurrentFrequency(schedule) {
  let res = null;

  const { units = null, timeUnit = null, startTime = null } = schedule;
  if (!timeUnit || !units || !startTime) {
    res = new Frequency("manual", COMPOSITE_UNITS.manual.unit);
  } else {
    const temp = new Frequency(units, timeUnit);
    getFrequencys().forEach(item => {
      if (item.value === temp.value) {
        res = temp;
      }
    });
  }

  if (!res) {
    res = new Frequency("custom", COMPOSITE_UNITS.custom.unit);
  }

  return res.value;
}
