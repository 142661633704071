import Vue from "vue";
import MaterializeOper from "./MaterializeOper";
import MaterializeTitle from "./MaterializeTitle";
import MaterializeForm from "./MaterializeForm";
import MaterializeRecords from "./MaterializeRecords";
import MaterializeEmpty from "./MaterializeEmpty";

class ShowComponent {
  constructor() {
    // 控制 MaterializeOper
    this.MaterializeOper = {
      key: "MaterializeOper",
      show: false,
      component: MaterializeOper
    };

    // 控制 MaterializeTitle
    this.MaterializeTitle = {
      key: "MaterializeTitle",
      show: false,
      component: MaterializeTitle,
      slot: "物化设置"
    };

    // 控制 MaterializeForm
    this.MaterializeForm = {
      key: "MaterializeForm",
      show: false,
      component: MaterializeForm
    };

    // 控制 MaterializeRecords
    this.MaterializeRecords = {
      key: "MaterializeRecords",
      show: false,
      component: MaterializeRecords
    };

    // 控制 MaterializeEmpty
    this.MaterializeEmpty = {
      key: "MaterializeEmpty",
      show: false,
      component: MaterializeEmpty
    };
  }

  /**
   * 设置组件的可见性
   * @param {object} showConfig { MaterializeOper, MaterializeTitle, MaterializeForm, MaterializeRecords, MaterializeEmpty }
   */
  setShow(
    showConfig = {
      MaterializeOper: false,
      MaterializeTitle: false,
      MaterializeForm: false,
      MaterializeRecords: false,
      MaterializeEmpty: false
    }
  ) {
    for (const key in this) {
      if (Object.hasOwnProperty.call(this, key)) {
        const element = this[key];
        element.show = showConfig[key] || false;
      }
    }
  }

  // 获取可见的组件
  getShowComponets() {
    const components = [];
    for (const key in this) {
      if (Object.hasOwnProperty.call(this, key)) {
        const element = this[key];
        if (element.show) {
          components.push(element);
        }
      }
    }
    return components;
  }
}

const materialize = new ShowComponent();

export default Vue.observable(materialize);
