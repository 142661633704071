const presets = {
  // color
  darkblue: "rgb(85, 134, 255)",
  blue: "#1890ff",
  lime: "#a0d911",
  darklime: "#92c70c",
  gray: "#8c8c8c",
  red: "#ed3f14",
  green: "#19be6b",
  yellow: "rgb(255, 122, 0)",
  purple: "rgb(123, 103, 238)",
  // status
  normal: null,
  pending: null,
  running: null,
  incomplete: null,
  failed: null,
  succeeded: null,
  cancelled: null
};

presets.normal = presets.gray;
presets.pending = presets.darkblue;
presets.running = presets.blue;
presets.incomplete = presets.darklime;
presets.failed = presets.red;
presets.succeeded = presets.green;
presets.cancelled = presets.yellow;

export default presets;
