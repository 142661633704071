<template>
  <div class="materialize-container">
    <RecordsItem
      v-for="task in tasks"
      :key="task.id"
      :task="task"
    ></RecordsItem>
    <a-empty v-if="!tasks || !tasks.length" class="empty" />
  </div>
</template>

<script>
import RecordsItem from "./MaterializeRecordsItem.vue";
import materialize from "./materialize";
export default {
  components: { RecordsItem },
  computed: {
    tasks() {
      return materialize.tasks;
    }
  }
};
</script>

<style lang="less" scoped>
.materialize-container {
  padding: 20px 10px;
  .empty {
    padding: 80px 0;
  }
}
</style>
