import ansiparser from "./ansiparse";

export default {
  functional: true,
  render(h, context) {
    const props = context.props;
    const phrases = ansiparser(props.source.line || "").map(item => {
      const classList = [];
      if (item.foreground) {
        classList.push(`fg-${item.foreground}`);
      }
      if (item.background) {
        classList.push(`bg-${item.background}`);
      }
      if (item.bold) {
        classList.push("bold");
      }
      if (item.italic) {
        classList.push("italic");
      }
      if (item.underline) {
        classList.push("underline");
      }
      return <span class={classList}>{item.text}</span>;
    });
    return [
      <span class="no" onClick={() => props.focuson(props.index)}>
        {props.index}
      </span>,
      <span class="code">{phrases}</span>
    ];
  }
};
