<template>
  <div :class="['records-status', task.status]">
    <JobStatus :status="task.status" />
    <div class="content">
      <slot>
        <span class="title">
          {{ task.status | localeStatus }}
          <span class="wasted-time"> - 耗时{{ wastedTime }}</span>
        </span>
        <span class="description">
          {{ task.createdAt }} ~ {{ task.endedAt }}
        </span>
      </slot>
    </div>
  </div>
</template>

<script>
import JobStatus from "@/views/connections/components/job-status";
import {
  localeStatus,
  colorPresets
} from "@/views/connections/components/job-status";
import { timeDiff } from "@/views/connections/utils/helpers";

export default {
  components: { JobStatus },
  props: {
    task: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      colorPresets: colorPresets
    };
  },
  computed: {
    wastedTime() {
      return timeDiff(this.task.createdAt, this.task.endedAt);
    }
  },
  filters: { localeStatus }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.records-status {
  display: flex;
  align-items: center;
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    line-height: 20px;
    font-size: 14px;
    .description {
      font-size: 13px;
      color: @text-color-secondary;
    }
  }
  &.failed .title {
    color: @red-6;
  }
  &.running .description,
  &.running .title .wasted-time {
    display: none;
  }
}
</style>
