// 时间单位
export const TIME_UNITS = {
  minutes: {
    unit: "minutes",
    desc: "分钟"
  },
  hours: {
    unit: "hours",
    desc: "小时"
  },
  days: {
    unit: "days",
    desc: "天"
  },
  weeks: {
    unit: "weeks",
    desc: "周"
  },
  months: {
    unit: "months",
    desc: "月"
  }
};

// 自定义单位
export const CUSTOM_UNITS = {
  manual: {
    unit: "manual",
    desc: "手动物化"
  },
  custom: {
    unit: "custom",
    desc: "自定义"
  }
};

// 时间单位 + 自定义单位
export const COMPOSITE_UNITS = Object.assign({}, TIME_UNITS, CUSTOM_UNITS);
