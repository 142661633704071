<template>
  <VirtualList
    data-key="index"
    :data-sources="sources"
    :data-component="logLine"
    :extra-props="{ focusedIndex, focuson }"
    :item-class-add="addItemClass"
    class="lazylog"
  />
</template>
<script>
import VirtualList from "vue-virtual-scroll-list";
import logLine from "./render-line";

export default {
  components: { VirtualList },
  props: {
    // 字符串数组，即 connection job 的 loglines 结构
    lines: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return { logLine, focusedIndex: null };
  },
  computed: {
    sources() {
      return this.lines.map((line, index) => ({ line, index }));
    }
  },
  methods: {
    addItemClass(index) {
      return index == this.focusedIndex ? "focused" : "";
    },
    focuson(index = null) {
      this.focusedIndex = index == this.focusedIndex ? null : index;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.lazylog {
  height: 100%;
  overflow: auto;
  white-space: pre;
}
.lazylog /deep/ div[role="listitem"] {
  line-height: 1.8;
  font-size: 10px;
  color: rgb(1, 0, 71);
  font-family: "Monaco", monospace, -apple-system, BlinkMacSystemFont,
    "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  &:hover,
  &:hover .code {
    background: rgb(217, 217, 224);
  }
  &.focused,
  &.focused .code {
    background: rgb(179, 179, 189);
  }
}
.lazylog {
  /deep/ .no {
    display: inline-block;
    width: 45px;
    padding-right: 1em;
    text-align: right;
    cursor: pointer;
    color: #6f6f6f;
    user-select: none;
  }
  /deep/ .code {
    display: inline-block;
    padding-left: 12px;
  }

  // ansi styles
  .seek(@prefix, @attr) {
    /deep/ .@{prefix}-black {
      @{attr}: #141414;
    }
    /deep/ .@{prefix}-red {
      @{attr}: if(@prefix = fg, @red-6, @red-4);
    }
    /deep/ .@{prefix}-green {
      @{attr}: if(@prefix = fg, @green-6, @green-4);
    }
    /deep/ .@{prefix}-yellow {
      @{attr}: if(@prefix = fg, @gold-6, @gold-4);
    }
    /deep/ .@{prefix}-blue {
      @{attr}: if(@prefix = fg, @blue-6, @blue-4);
    }
    /deep/ .@{prefix}-magenta {
      @{attr}: #eb2f96;
    }
    /deep/ .@{prefix}-cyan {
      @{attr}: #13c2c2;
    }
    /deep/ .@{prefix}-white {
      @{attr}: #fff;
    }
    /deep/ .@{prefix}-grey {
      @{attr}: #8c8c8c;
    }
  }
  .seek(fg, color);
  .seek(bg, background-color);
  /deep/ .bold {
    font-weight: bold;
  }
  /deep/ .italic {
    font-style: italic;
  }
  /deep/ .underline {
    text-decoration: underline;
  }
}
</style>
