<template>
  <a-tooltip :title="computedTitle">
    <!-- <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
    >
      <path
        d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
        :fill="fillColor"
        fill-opacity=".08"
      ></path>
      <path
        d="M512 512m-426.666667 0a426.666667 426.666667 0 1 0 853.333334 0 426.666667 426.666667 0 1 0-853.333334 0Z"
        :fill="fillColor"
        fill-opacity=".12"
      ></path>
      <path
        d="M512 512m-256 0a256 256 0 1 0 512 0 256 256 0 1 0-512 0Z"
        :fill="fillColor"
      ></path>
    </svg> -->
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
    >
      <path
        d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
        :fill="fillColor"
        fill-opacity=".25"
      />
      <path
        d="M512 512m-398.222222 0a398.222222 398.222222 0 1 0 796.444444 0 398.222222 398.222222 0 1 0-796.444444 0Z"
        :fill="fillColor"
      />
      <path
        d="M483.555556 483.555556m-312.888889 0a312.888889 312.888889 0 1 0 625.777777 0 312.888889 312.888889 0 1 0-625.777777 0Z"
        fill="#F1F6F8"
        opacity=".3"
      />
      <path
        d="M625.777778 455.111111m-56.888889 0a56.888889 56.888889 0 1 0 113.777778 0 56.888889 56.888889 0 1 0-113.777778 0Z"
        fill="#F1F6F8"
        opacity=".5"
      />
    </svg>
  </a-tooltip>
</template>

<script>
import colorPresets from "./color-presets";
import localeStatus from "./locale-status";

export default {
  props: {
    status: {
      type: String,
      default: "normal"
    },
    color: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: String,
      default: "1em"
    },
    title: String,
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    computedTitle() {
      return this.showTitle ? this.title || localeStatus(this.status) : null;
    },
    fillColor() {
      const color = this.color;
      return colorPresets[this.status] || colorPresets[color] || color;
    }
  }
};
</script>
