import Vue from "vue";
import { message } from "ant-design-vue";
import {
  getMaterializeDetail,
  saveMaterialize,
  getMaterializeTasks,
  getMaterializeTasksLog,
  getMaterializeRun,
  delMaterialize
} from "@/api/datasheet";
import showComponent from "./show-component";

function nullToObject(obj) {
  if (obj) {
    return obj;
  }
  return {};
}

class Materialize {
  constructor() {
    /**
     * @type {string} datasheetId 当前视图 id
     */
    this.datasheetId = null;
    /**
     * @type {boolean} loading 是否显示加载
     */
    this.loading = false;
    /**
     * @type {object} configDetail 物化配置详情
     */
    this.configDetail = null;
    /**
     * @type {array} tasks 运行记录
     */
    this.tasks = null;
  }

  /**
   * 物化配置 id
   */
  get materializeId() {
    return this.configDetail ? this.configDetail.id : null;
  }

  /**
   * 初始化
   */
  async init(datasheetId) {
    this.datasheetId = datasheetId;
    this.loading = true;
    const detail = await this.getDetail();
    this.loading = false;

    if (detail.isFail) {
      message.error({ content: "获取物化配置出错", duration: 2 });
      return;
    }
    if (detail.id) {
      // 有配置存在
      showComponent.setShow({
        MaterializeOper: true,
        MaterializeRecords: true
      });
      this.configDetail = detail;
      this.getTasks();
    } else {
      // 没有配置过物化
      showComponent.setShow({ MaterializeEmpty: true });
      this.configDetail = null;
      this.tasks = null;
    }
  }

  /**
   * 获取物化配置详情
   */
  getDetail() {
    return getMaterializeDetail({ datasheetId: this.datasheetId })
      .then(res => {
        const data = res.data || {};
        data.schedule = nullToObject(data.schedule);
        return data;
      })
      .catch(error => {
        return { isFail: true, error };
      });
  }

  /**
   * 保存物化配置
   */
  saveMaterialize(data) {
    const { units, timeUnit, startTime } = data.schedule;
    if (!units && !timeUnit && !startTime) {
      data.schedule = null;
    }
    const req = {
      datasheetId: this.datasheetId,
      policy: null,
      ...data
    };
    this.loading = true;
    saveMaterialize(req)
      .then(res => {
        message.success({ content: "保存成功", duration: 2 });
        const data = res.data || {};
        data.schedule = nullToObject(data.schedule);
        showComponent.setShow({
          MaterializeOper: true,
          MaterializeRecords: true
        });
        this.configDetail = data;
      })
      .catch(() => {
        message.error({ content: "保存失败", duration: 2 });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * 删除物化
   */
  delete() {
    if (!this.materializeId) {
      throw new Error("该视图还没有配置物化");
    }
    this.loading = true;
    delMaterialize(this.materializeId)
      .then(() => {
        message.success({ content: "删除成功", duration: 2 });
      })
      .catch(() => {
        message.error({ content: "删除失败", duration: 2 });
      })
      .finally(() => {
        this.loading = false;
        this.init(this.datasheetId);
      });
  }

  /**
   * 立即运行物化
   */
  running() {
    if (!this.materializeId) {
      throw new Error("该视图还没有配置物化");
    }
    this.loading = true;
    getMaterializeRun(this.materializeId)
      .then(() => {})
      .catch(() => {
        message.error({ content: "运行失败", duration: 2 });
      })
      .finally(() => {
        this.loading = false;
        this.getTasks();
      });
  }

  /**
   * 获取物化运行列表
   */
  getTasks() {
    if (!this.materializeId) {
      throw new Error("该视图还没有配置物化");
    }
    const req = {
      materializeId: this.configDetail.id,
      page: 1,
      size: 100
    };
    this.loading = true;
    getMaterializeTasks(req)
      .then(res => {
        res.data = res.data || [];
        this.tasks = res.data.map(item => item.job);
      })
      .catch(() => {
        message.error({ content: "获取运行记录失败", duration: 2 });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * 获取对应运行记录的运行日志
   */
  getLogs(taskId) {
    if (!taskId) {
      throw new Error("记录 id 为空");
    }
    return getMaterializeTasksLog(taskId)
      .then(res => {
        res.data = res.data || {};
        let logs = null;
        try {
          logs = res.data.attempts[0].logs.logLines;
        } catch (error) {
          logs = [];
        }
        return logs;
      })
      .catch(() => {
        message.error({ content: "获取运行日志录失败", duration: 2 });
      });
  }

  /**
   * 校验表单
   */
  validate(formDate, frequencyObj) {
    const { isManual, isCustom } = frequencyObj;

    if (
      isCustom &&
      (!formDate.schedule.timeUnit ||
        !formDate.schedule.units ||
        !formDate.schedule.startTime)
    ) {
      return false;
    }

    if (!isManual && !isCustom && !formDate.schedule.startTime) {
      return false;
    }

    return true;
  }
}

const materialize = new Materialize();

export default Vue.observable(materialize);
