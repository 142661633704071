const statusLabelMap = {
  normal: "就绪",
  pending: "等待调度",
  running: "运行中",
  incomplete: "已终止",
  failed: "失败",
  succeeded: "成功",
  cancelled: "已取消"
};

export default function(status) {
  return statusLabelMap[status] || null;
}
