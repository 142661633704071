<template>
  <div class="item-container">
    <div class="item" @click="getLogs(task.id)">
      <RecordsStatus class="status" :task="task" />
      <div class="icon-con">
        <a-tooltip :title="showLog ? '收起日志' : '展开日志'">
          <i
            :class="[
              'ndl-icon-chevron-right',
              'icon',
              showLog ? 'show-log' : ''
            ]"
          ></i>
        </a-tooltip>
      </div>
    </div>
    <div v-if="showLog" class="log-container">
      <a-skeleton
        v-if="loadingLogs"
        class="skeleton"
        size="small"
        :title="false"
        :paragraph="{ rows: 5 }"
        active
      />
      <div v-else>
        <a-empty v-if="!logs.length" class="empty" />
        <LazyLog v-else class="output" :lines="logs" />
      </div>
    </div>
  </div>
</template>

<script>
import RecordsStatus from "./MaterializeRecordsStatus";
import LazyLog from "@/components/vue-lazylog";
import materialize from "./materialize";

export default {
  components: { RecordsStatus, LazyLog },
  props: {
    task: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showLog: false,
      loadingLogs: false,
      logs: []
    };
  },
  methods: {
    getLogs(taskId) {
      this.showLog = !this.showLog;
      if (this.showLog && !this.logs.length) {
        this.loadingLogs = true;
        materialize
          .getLogs(taskId)
          .then(res => {
            this.logs = res;
          })
          .finally(() => {
            this.loadingLogs = false;
          });
      }
    }
  }
};
</script>

<style lang="less" scoped>
@height: 500px;
.item-container {
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.09);
  margin-bottom: 15px;
  border-radius: 8px;
  .item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    .status {
      flex: 1;
    }
    .icon-con {
      border-radius: 3px;
      &:hover {
        background-color: #eee;
      }
      .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        transform: rotate(0deg);
        transition: all 0.3s;
      }
      .show-log {
        transform: rotate(90deg);
        transition: all 0.3s;
      }
    }
  }
  .log-container {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #fafafa;
    .skeleton {
      box-sizing: border-box;
      padding: 8px;
    }
    .output {
      height: @height;
      overflow: auto;
      padding: 8px 0;
      animation: expand 0.3s;
    }
    .empty {
      padding: 30px 0;
    }
  }
}
@keyframes expand {
  0% {
    height: 0px;
  }
  100% {
    height: @height;
  }
}
</style>
