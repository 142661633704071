export function formatBytes(bytes = 0) {
  if (!bytes || bytes === 0) {
    return "0 Bytes";
  }
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
}

export function timeDiff(from, to) {
  // safari 不支持 YYYY-MM-dd HH:mm:ss 格式的日期
  const normalize = str => String(str).replace(/-/g, "/");
  from = new Date(normalize(from));
  to = new Date(normalize(to));
  let diff = Math.round((to - from) / 1000);
  const d = Math.floor(diff / (3600 * 24));
  diff %= 3600 * 24;
  const h = Math.floor(diff / 3600);
  diff %= 3600;
  const m = Math.floor(diff / 60);
  diff %= 60;
  const units = [];
  if (d) {
    units.push(`${d}天`);
  }
  if (h) {
    units.push(`${h}小时`);
  }
  if (m) {
    units.push(`${m}分`);
  }
  const s = diff;
  let ss = `${s}秒`;
  if (m) {
    ss = ss.padStart(3, 0);
  }
  units.push(ss);
  return units.join("");
}

export function localeConfigType(type) {
  switch (type) {
    case "reset_connection":
      return "重置";
    case "sync":
      return "同步";
    default:
      return "";
  }
}

export function jobinfo(record, attemptIndex) {
  const attempts = record.attempts || [];
  // 默认显示最后一次重试运行状态
  if (!attemptIndex && attemptIndex != 0) {
    attemptIndex = attempts.length - 1;
  }
  const lastAttempt = attempts[attemptIndex];
  const job = record.job || {};
  const values = [];
  if (lastAttempt) {
    values.push(
      `耗时 ${timeDiff(lastAttempt.createdAt, lastAttempt.updatedAt)}`,
      `${formatBytes(lastAttempt.bytesSynced)}`,
      `${lastAttempt.recordsSynced || 0} 条记录`
    );
  } else {
    values.push(`耗时 ${timeDiff(job.createdAt, job.updatedAt)}`);
  }
  if (attempts.length > 1) {
    values.push(`重试 ${attempts.length} 次`);
  }
  values.push(`${localeConfigType(job.configType)}`);
  return values.join(" | ");
}
