<template>
  <div class="form-container">
    <a-form layout="vertical" class="ndl-antd">
      <a-form-item label="调度频率" required>
        <a-select v-model="frequency" placeholder="请选择调度频率">
          <a-select-option
            v-for="item in frequencys"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="frequencyObj.isCustom" label="自定义调度频率" required>
        <a-input v-model="formData.schedule.units" placeholder="请输入调度频率">
          <a-select
            v-model="formData.schedule.timeUnit"
            slot="addonAfter"
            style="width: 80px"
          >
            <a-select-option
              v-for="item in timeUnits"
              :key="item.unit"
              :value="item.unit"
            >
              {{ item.desc }}
            </a-select-option>
          </a-select>
        </a-input>
      </a-form-item>
      <a-form-item v-if="!frequencyObj.isManual" label="开始时间" required>
        <a-date-picker
          v-model="formData.schedule.startTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          show-time
          placeholder="请选择开始时间"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item>
        <div class="footer">
          <span class="ndl-text-desc">保存物化规则设置用于物化视图</span>
          <a-button @click="close()">
            取消
          </a-button>
          <a-tooltip :title="!isValid ? '请填写必填项' : ''">
            <a-button type="primary" @click="save()" :disabled="!isValid">
              保存
            </a-button>
          </a-tooltip>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { getFrequencys, getTimeUnits, getCurrentFrequency } from "./js/utils";
import materialize from "./materialize";
import showComponent from "./show-component";

export default {
  data() {
    return {
      frequencys: getFrequencys(),
      timeUnits: getTimeUnits(),
      formData: {
        id: null,
        schedule: {
          units: null,
          timeUnit: null,
          startTime: null
        }
      },
      frequency: "",
      frequencyObj: {},
      isValid: false
    };
  },
  watch: {
    configDetail() {
      this.init();
    },
    // 监听 调度频率 变化来设置 formData.schedule
    frequency(value) {
      this.frequencyObj = JSON.parse(value);
      const { units, timeUnit, isManual, isCustom } = this.frequencyObj;
      const startTime = this.formData.schedule.startTime || null;
      const schedule = this.configDetail ? this.configDetail.schedule : {};

      if (!isManual && !isCustom) {
        this.setSchedule(units, timeUnit, startTime || schedule.startTime);
        return;
      }
      if (isManual) {
        this.setSchedule(null, null, null);
        return;
      }
      if (isCustom && !schedule.timeUnit) {
        this.setSchedule(null, this.timeUnits[0].unit, startTime);
        return;
      }
      if (isCustom && schedule.timeUnit) {
        this.setSchedule(schedule.units, schedule.timeUnit, schedule.startTime);
        return;
      }
    },
    // 校验字段
    formData: {
      deep: true,
      handler: function() {
        this.validate();
      }
    },
    frequencyObj() {
      this.validate();
    }
  },
  computed: {
    configDetail() {
      return materialize.configDetail;
    },
    hasConfig() {
      return materialize.materializeId;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.configDetail) {
        // 无配置时的默认填充
        this.frequency = this.frequencys[0].value;
        this.setFormData({ id: null });
      } else {
        // 有配置
        this.frequency = getCurrentFrequency(this.configDetail.schedule);
        this.setFormData(this.configDetail);
      }
    },

    setFormData(formData) {
      this.formData.id = formData.id;
    },

    setSchedule(units, timeUnit, startTime) {
      this.formData.schedule.units = units;
      this.formData.schedule.timeUnit = timeUnit;
      this.formData.schedule.startTime = startTime;
    },

    validate() {
      this.isValid = materialize.validate(this.formData, this.frequencyObj);
    },

    close() {
      let showConfig = null;
      if (this.hasConfig) {
        showConfig = {
          MaterializeOper: true,
          MaterializeRecords: true
        };
      } else {
        showConfig = { MaterializeEmpty: true };
      }
      showComponent.setShow(showConfig);
    },

    save() {
      materialize.saveMaterialize(this.formData);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.form-container {
  margin: 24px 10px;
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ndl-text-desc {
      flex: 1 1 0;
    }
    .ant-btn {
      margin-left: 10px;
    }
  }
}
</style>
