<template>
  <div class="container">
    <Focusable v-if="showFrom" @click="setShowFrom()" size="large">
      <i class="ndl-icon-settings ndl-icon"></i>
      <span class="label">物化设置</span>
    </Focusable>
    <!-- <Focusable
      v-if="showRecords"
      @click="setShowFrom({ MaterializeRecords: true })"
      size="large"
    >
      <i class="ndl-icon-file-text ndl-icon"></i>
      <span class="label">运行记录</span>
    </Focusable> -->
    <Focusable @click="running()" size="large">
      <i class="ndl-icon-play ndl-icon"></i>
      <span class="label">立即物化</span>
    </Focusable>
    <Focusable @click="deleteMaterialize()" size="large">
      <i class="ndl-icon-trash-2 ndl-icon red-color"></i>
      <span class="label red-color">删除物化</span>
    </Focusable>
  </div>
</template>

<script>
import Focusable from "../../focusable";
import materialize from "./materialize";
import showComponent from "./show-component";

export default {
  components: { Focusable },
  data() {
    return {
      tips: "设置物化规则后，才可进行该操作"
    };
  },
  computed: {
    showFrom() {
      return !showComponent.MaterializeForm.show;
    },
    showRecords() {
      return !showComponent.MaterializeRecords.show;
    }
  },
  methods: {
    setShowFrom() {
      const showConfig = {
        MaterializeTitle: true,
        MaterializeForm: true
      };
      showComponent.setShow(showConfig);
    },
    running() {
      materialize.running();
    },
    deleteMaterialize() {
      this.$confirm({
        title: "删除物化",
        content: `删除操作无法撤销，会重置物化配置、清空运行记录，确定要删除此物化吗？`,
        okType: "danger",
        onOk: function() {
          materialize.delete();
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.container {
  display: flex;
  align-items: center;
  padding: 10px;
  // padding-bottom: 20px;
  // border-bottom: 1px solid #ebebeb;
  /deep/ .focusable {
    width: auto;
    padding: 3px 8px;
  }
  .ndl-icon {
    font-size: 14px;
    margin-top: 2px;
  }
  .label {
    margin-left: 5px;
    font-size: 13px;
  }
  .red-color {
    color: @red-6;
  }
}
</style>
