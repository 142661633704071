<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  // padding-bottom: 20px;
  // border-bottom: 1px solid #ebebeb;
  font-size: 15px;
}
</style>
